import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomMessage, getCookie, getSideFlag } from './common/mainfunctions';
import { useSnackbar } from 'notistack';

const Entry = () => {
  const navigate = useNavigate();
const {enqueueSnackbar}=useSnackbar()
  useEffect(() => {
    // Check if the user is authenticated
    const token = getCookie("userToken") // Replace with actual token validation
    const domain=getCookie('userdomain')
    //   CustomMessage(`inside teams${token}`, "success", enqueueSnackbar);
    
    if (token&&token !=undefined&&domain) {
    //   CustomMessage(`inside teams have token${token}`, "success", enqueueSnackbar);

        // const dashboardUrl = `https://devhrassist.app/signup`;
        const dashboardUrl = `https://${domain}.hrassist.app/dashboard`;
    
        window.open(dashboardUrl,'_self'); 
    } else {
      navigate('/signup'); // Otherwise, redirect to signup
    }
  }, [navigate]);

  return <div>Loading...</div>; // Optional loading indicator while redirecting
};

export default Entry;
